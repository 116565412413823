import debounce from 'debounce'
import { useCallback, useEffect, useRef, useState } from 'react'

import { unstructured } from '@src/tracker'

import { IMPRESSION_EVENT_NAME } from '../../constants/impression_tracking'
import { constructSnowplowEventData, hasData } from '../../tracker/helpers'

import { DELAY, IMPRESSION_ERROR_NAME } from './constants'
import { hasImpressionTrackingSupport } from './helpers'

const useImpressionListener = (handleError) => {
  const [impressionViewData, setImpressionViewData] = useState([])
  const [impressionLoadData, setImpressionLoadData] = useState([])

  const impressionViewStateRef = useRef(impressionViewData)
  const setImpressionViewDataRef = (data) => {
    impressionViewStateRef.current = data
    setImpressionViewData(data)
  }

  const impressionLoadStateRef = useRef(impressionLoadData)
  const setImpressionLoadDataRef = (data) => {
    impressionLoadStateRef.current = data
    setImpressionLoadData(data)
  }

  const handleEvents = (e) => {
    if (e?.detail?.impression_view) {
      const { ...snowplowData } = e.detail.impression_view

      setImpressionViewDataRef([
        ...impressionViewStateRef.current,
        snowplowData
      ])
    }

    if (e?.detail?.impression_load) {
      const { ...snowplowData } = e.detail.impression_load

      setImpressionLoadDataRef([
        ...impressionLoadStateRef.current,
        snowplowData
      ])
    }
  }

  const handleSnowplowData = (data) => {
    const batchedEvents = constructSnowplowEventData(data)

    batchedEvents.forEach((batch) => {
      unstructured(batch?.event?.schema, batch?.event?.data, batch?.contexts)
    })
  }

  const resetState = useCallback(() => {
    setImpressionViewDataRef([])
    setImpressionLoadDataRef([])
  }, [])

  const processData = () => {
    handleSnowplowData({
      impressionView: impressionViewStateRef.current,
      impressionLoad: impressionLoadStateRef.current
    })

    resetState()
  }

  const handleStateChange = () => {
    if (
      hasData(impressionViewStateRef.current) ||
      hasData(impressionLoadStateRef.current)
    ) {
      processData()
    }
  }

  const handleStateChangeDebounced = debounce(handleStateChange, DELAY)

  useEffect(() => {
    if (hasImpressionTrackingSupport()) {
      try {
        document.addEventListener(IMPRESSION_EVENT_NAME, handleEvents)
      } catch (error) {
        handleError?.(error, IMPRESSION_ERROR_NAME)
      }
    }
    return () => {
      document.removeEventListener(IMPRESSION_EVENT_NAME, handleEvents)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      handleStateChangeDebounced()
    } catch (error) {
      handleError?.(error, IMPRESSION_ERROR_NAME)
    }
  }, [impressionViewData, handleStateChangeDebounced]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useImpressionListener
