import root from 'window-or-global'

import { isTypeFunc } from '@helpers/index'

import {
  USER_COPIED_CODE,
  CODE_ISSUANCE_ERROR,
  GENERAL_ERROR,
  PAGE_NOT_FOUND_ERROR,
  EXTERNAL_LINK_CLICK
} from './constants'

export const hasIosInterface = (name) => {
  const eventHandler = root?.webkit?.messageHandlers?.[name]?.postMessage
  return !!(eventHandler && isTypeFunc(eventHandler))
}

export const hasAndroidInterface = (name) => {
  const eventHandler = root?.Android?.[name]
  return !!(eventHandler && isTypeFunc(eventHandler))
}

export const sendIosPayloadEvent = (name, body) => {
  if (hasIosInterface(name))
    root.webkit.messageHandlers[name]?.postMessage(body)
}

export const sendIosEvent = (name) => {
  if (hasIosInterface(name)) root.webkit.messageHandlers[name]?.postMessage()
}

export const sendAndroidPayloadEvent = (name, body) => {
  if (hasAndroidInterface(name)) root.Android[name](body)
}

export const sendAndroidEvent = (name) => {
  if (hasAndroidInterface(name)) root.Android[name]()
}

export const sendUserCopiedCodeEvent = (code) => {
  sendIosPayloadEvent(USER_COPIED_CODE, code)
  sendAndroidPayloadEvent(USER_COPIED_CODE, code)
}

export const sendCodeIssuanceErrorEvent = (error) => {
  sendIosPayloadEvent(CODE_ISSUANCE_ERROR, error)
  sendAndroidPayloadEvent(CODE_ISSUANCE_ERROR, error)
}

export const sendGeneralErrorEvent = (error) => {
  sendIosPayloadEvent(GENERAL_ERROR, error)
  sendAndroidPayloadEvent(GENERAL_ERROR, error)
}

export const sendPageNotFoundErrorEvent = (error) => {
  sendIosPayloadEvent(PAGE_NOT_FOUND_ERROR, error)
  sendAndroidPayloadEvent(PAGE_NOT_FOUND_ERROR, error)
}

export const sendExternalLinkClickEvent = (url) => {
  sendIosPayloadEvent(EXTERNAL_LINK_CLICK, url)
  sendAndroidPayloadEvent(EXTERNAL_LINK_CLICK, url)
}
