import Colours from '@thebeansgroup/ui_style_helpers/colours'

import {
  BRAND_BY_CONSUMER_GROUP,
  ENGLISH_SPEAKING_COUNTRIES
} from '../constants'

export const hasConnectOfferPage = (props) =>
  !!(props?.connectOfferPage || props?.viewer?.connectOfferPage)

export const templateConfigIsValid = (connectOfferPage) => {
  if (!connectOfferPage) return false

  try {
    return isObject(JSON.parse(connectOfferPage.templateConfig))
  } catch (error) {
    return false
  }
}

export const getTemplateConfig = (connectOfferPage) => {
  if (!templateConfigIsValid(connectOfferPage)) return {}

  return getCleanedTemplateConfig(connectOfferPage)
}

export const getCleanedTemplateConfig = (connectOfferPage) => {
  const templateConfig = JSON.parse(connectOfferPage.templateConfig)
  deleteAllFalsyObjectProperties(templateConfig)
  return templateConfig
}

const deleteAllFalsyObjectProperties = (target) => {
  if (isObject(target)) {
    for (const key in target) {
      deleteFalsyObjectProperty(target, key, deleteAllFalsyObjectProperties)
    }
  }
}

const deleteFalsyObjectProperty = (target, key, cb) => {
  if (!target[key]) {
    delete target[key]
  } else {
    cb(target[key])
  }
}

export const hasBackgroundColour = (templateConfig) => {
  return (
    hasTemplateConfigProperty(templateConfig, 'background') &&
    !!templateConfig.background.backgroundColour
  )
}

export const getBackgroundColour = (
  templateConfig,
  defaultBackgroundColour = Colours('white', 300)
) => {
  if (!hasBackgroundColour(templateConfig)) return defaultBackgroundColour

  return templateConfig.background.backgroundColour
}

export const hasImage = (templateConfig, type) => {
  return (
    hasTemplateConfigProperty(templateConfig, 'image') &&
    !!templateConfig.image[type] &&
    Object.keys(templateConfig.image[type]).length !== 0
  )
}

const hasTemplateConfigProperty = (templateConfig, property) => {
  return !!templateConfig && !!templateConfig[property]
}

export const getDesktopImage = (templateConfig) => {
  if (!hasImage(templateConfig, 'desktop')) return null

  return templateConfig.image.desktop.jpeg
}

export const getMobileImage = (templateConfig) => {
  if (!hasImage(templateConfig, 'mobile')) return null

  return templateConfig.image.mobile.jpeg
}

export const isDevelopmentEnv = () => process.env.NODE_ENV === 'development'

export const hasOffer = (props) => {
  const { connectOfferPage } = props.viewer
  return connectOfferPage && connectOfferPage.offer
}

export const isObject = (obj) => !!obj && typeof obj === 'object'

export const getViewer = (props) => props?.viewer || null

export const getConnectOfferPageFromViewer = (props) =>
  props?.viewer?.connectOfferPage || null

export const getConnectOfferPagePropFromViewer = (props, propName) =>
  props?.viewer?.connectOfferPage?.[propName] || null

export const isInteger = (number) => number === parseInt(number, 10)

export const isString = (str) => typeof str === 'string'

export const lowerCaseString = (str) => {
  if (!isString(str)) return str

  return str?.toLowerCase()
}

export const isTypeFunc = (func) => typeof func === 'function'

export const trimObjectStringValues = (obj) => {
  const objCopy = { ...obj }
  Object.keys(objCopy).forEach((key) => {
    if (isString(objCopy[key])) objCopy[key] = objCopy[key].trim()
  })
  return objCopy
}

export const isConsumerGroupGraduate = (consumerGroup) =>
  consumerGroup?.toLowerCase() === 'graduate'

export const isConsumerGroupStudent = (consumerGroup) =>
  consumerGroup?.toLowerCase() === 'student'

export const getActiveOffers = (offers) =>
  offers.filter((offer) => offer?.status !== 'scheduled' && offer?.enabled)

export const getArrayOfOffersCCGs = (activeOffers) => {
  const consumerGroupsOfOffers = activeOffers.map(
    (offer) => offer.consumerGroup
  )
  const dedupedConsumerGroups = [...new Set(consumerGroupsOfOffers)]

  return dedupedConsumerGroups
}

export const showMultiOfferPage = (offers) => {
  if (!offers) return false

  const activeOffers = getActiveOffers(offers)
  const dedupedConsumerGroups = getArrayOfOffersCCGs(activeOffers)

  return activeOffers.length > 1 && dedupedConsumerGroups.length > 1
}

export const getBeansBrandFromConsumerGroup = (consumerGroup) => {
  return BRAND_BY_CONSUMER_GROUP[consumerGroup?.toLowerCase()] ?? 'beansId'
}

const formatConsumerGroup = (consumerGroup, replaceCharacter) => {
  return consumerGroup?.replace(/[\W_]+/g, replaceCharacter)
}

export const formatConsumerGroupForTranslations = (consumerGroup) => {
  return formatConsumerGroup(consumerGroup, '_')?.toLowerCase()
}

export const titleCaseConsumerGroup = (consumerGroup) => {
  const formattedConsumerGroup = formatConsumerGroup(consumerGroup, ' ')

  const titleCasedCCG = formattedConsumerGroup
    .split(' ')
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(' ')

  return titleCasedCCG
}

export const getSelectedOfferId = (
  offerUidFromParams,
  offers,
  connectOfferPage
) => {
  if (!offers) return null

  const { multiOffer } = connectOfferPage
  const activeOffers = getActiveOffers(offers)
  return offerUidFromParams || getUidFromOffers(activeOffers, multiOffer) || ''
}

export const getUidFromOffers = (activeOffers, multiOffer) => {
  const dedupedConsumerGroups = getArrayOfOffersCCGs(activeOffers)
  let activeOfferUid = false

  if (
    dedupedConsumerGroups.length === 1 &&
    !isMultiOfferWithMultipleOffers(activeOffers, multiOffer)
  ) {
    activeOfferUid = activeOffers[0].uid
  }

  return activeOfferUid
}

export const isMultiOfferWithMultipleOffers = (offers, multiOffer) => {
  return offers.length > 1 && multiOffer
}

export const shouldShowMultiOfferPage = (multiOfferFlag) => {
  return process.env.MULTI_OFFER_CONNECT && multiOfferFlag
}

export const brandColourDefaultProp = () => {
  return Colours('violet', 500)
}

export const getActiveCCG = (connectOfferPage) => {
  if (!connectOfferPage) return null

  const { offers, multiOffer } = connectOfferPage

  const activeOffers = getActiveOffers(offers)
  const dedupedConsumerGroups = getArrayOfOffersCCGs(activeOffers)
  let activeCCG = ''

  if (
    dedupedConsumerGroups.length === 1 &&
    isMultiOfferWithMultipleOffers(activeOffers, multiOffer)
  ) {
    activeCCG = activeOffers[0].consumerGroup
  }

  return activeCCG
}

export const getSelectedConsumerGroup = (
  consumerGroupFromParams,
  connectOfferPage
) => {
  if (!connectOfferPage?.offers) return null

  return consumerGroupFromParams || getActiveCCG(connectOfferPage)
}

export const isEnglishSpeakingCountry = (countryCode) => {
  const country = countryCode?.toLowerCase()
  return ENGLISH_SPEAKING_COUNTRIES.includes(country)
}

export const isCustomisedConnect = (editorState) => {
  if (!editorState) return false

  return Object.keys(editorState).length !== 0
}

export const shouldRenderABTest = (flag, loading, consumerGroup) => {
  if (loading) return false

  if (!flag) return false

  if (!isConsumerGroupStudent(consumerGroup)) return false

  return true
}

export const isThirdPartyApp = () => {
  return window.parent !== window.self
}
