import loadable from '@loadable/component'
import makeRouteConfig from 'found/makeRouteConfig'
import Redirect from 'found/Redirect'
import Route from 'found/Route'

import App from '../app'
import Loader from '../components/loader'

import {
  OFFER_ROUTE,
  OFFER_ROUTE_WITH_NO_COUNTY,
  IMPLEMENTATION_OFFER_ROUTE,
  VERIFICATION_SUCCESSFUL_ROUTE,
  INAPP_OFFER_ROUTE,
  HOSTED_OFFER_ROUTE
} from './constants'
import { getServerErrors, getDefaultOfferRoute } from './helpers'

const OfferPage = loadable(
  () => import(/* webpackChunkName: "offer" */ '../pages/offer'),
  { fallback: <Loader /> }
)

const InappOfferPage = loadable(
  () =>
    import(
      /* webpackChunkName: "inapp_offer" */ '../pages/offer/inapp_offer.js'
    ),
  { fallback: <Loader /> }
)

const HostedOfferPage = loadable(
  () =>
    import(
      /* webpackChunkName: "hosted_offer" */ '../pages/offer/hosted_offer.js'
    ),
  { fallback: <Loader /> }
)

const OfferTermsPage = loadable(
  () => import(/* webpackChunkName: "offer_terms" */ '../pages/offer_terms'),
  { fallback: <Loader /> }
)

const HostedOfferTermsPage = loadable(
  () =>
    import(
      /* webpackChunkName: "hosted_offer_terms" */ '../pages/offer_terms/hosted_offer_terms.js'
    ),
  { fallback: <Loader /> }
)

const VerificationSuccessfulPage = loadable(
  () =>
    import(
      /* webpackChunkName: "verification_successful" */ '../pages/verification_successful'
    ),
  { fallback: <Loader /> }
)

const OfferTermsRoute = (
  <Route
    path='terms'
    Component={OfferTermsPage}
  />
)

export default makeRouteConfig(
  <Route
    Component={App}
    render={({ Component, props }) => (
      <Component
        error={getServerErrors()}
        {...props}
      />
    )}
  >
    <Redirect
      from={OFFER_ROUTE_WITH_NO_COUNTY}
      to={({ location, params }) =>
        getDefaultOfferRoute(params, location.search)
      }
    />
    <Redirect
      from={IMPLEMENTATION_OFFER_ROUTE}
      to={({ location, params }) =>
        getDefaultOfferRoute(params, location.search)
      }
    />
    <Route
      path={VERIFICATION_SUCCESSFUL_ROUTE}
      Component={VerificationSuccessfulPage}
    />
    <Route path={OFFER_ROUTE}>
      <Route Component={OfferPage} />
      {OfferTermsRoute}
    </Route>
    <Route path={INAPP_OFFER_ROUTE}>
      <Route Component={InappOfferPage} />
      {OfferTermsRoute}
    </Route>
    <Route path={HOSTED_OFFER_ROUTE}>
      <Route Component={HostedOfferPage} />
      <Route
        path='terms'
        Component={HostedOfferTermsPage}
      />
    </Route>
  </Route>
)
