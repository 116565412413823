import cookies from 'js-cookie'

import ThirdPartyTracking from '@thebeansgroup/third_party_tracking'

import {
  FACEBOOK_CONVERSION_API_SCHEMA,
  FACEBOOK_ID,
  FACEBOOK_CLICK_ID,
  SNAPCHAT_CONVERSION_API_SCHEMA,
  SNAPCHAT_USER_ID_COOKIE_NAME,
  TIKTOK_CONVERSION_API_SCHEMA,
  TIKTOK_ID,
  TIKTOK_CLICK_ID
} from '@constants/third_party_tracking'

export const hashedEmail = (email) =>
  ThirdPartyTracking.getNormalisedHashedString(email)

export const getFacebookConversionApiEvent = (facebookEventUid, email) => {
  return {
    schema: FACEBOOK_CONVERSION_API_SCHEMA,
    data: {
      facebook_event_uid: facebookEventUid || '',
      fbp: cookies.get(FACEBOOK_ID) || null,
      fbc: cookies.get(FACEBOOK_CLICK_ID) || null,
      hashed_email: hashedEmail(email)
    }
  }
}

export const getSnapchatConversionApiEvent = (pixelDedupeUid, email) => {
  return {
    schema: SNAPCHAT_CONVERSION_API_SCHEMA,
    data: {
      client_dedup_id: pixelDedupeUid || '',
      hashed_email: hashedEmail(email),
      uuid_c1: cookies.get(SNAPCHAT_USER_ID_COOKIE_NAME) || null
    }
  }
}

export const getTikTokConversionApiEvent = (
  pixelDedupeUid,
  email,
  contentId
) => {
  return {
    schema: TIKTOK_CONVERSION_API_SCHEMA,
    data: {
      tiktok_event_id: pixelDedupeUid || '',
      ttp: cookies.get(TIKTOK_ID) || null,
      ttclid: cookies.get(TIKTOK_CLICK_ID) || null,
      hashed_email: hashedEmail(email),
      content_id: contentId || ''
    }
  }
}

export const getConversionApiSnowplowContexts = (
  pixelDedupeUid,
  email,
  contentId
) => [
  getFacebookConversionApiEvent(pixelDedupeUid, email),
  getSnapchatConversionApiEvent(pixelDedupeUid, email),
  getTikTokConversionApiEvent(pixelDedupeUid, email, contentId)
]

export const sendCustomContentSquareVariable = (key, value) => {
  window._uxa = window._uxa || []
  window._uxa.push(['trackDynamicVariable', { key, value }])
}
