const hasValidProps = (property, onDefined) =>
  typeof window !== 'undefined' &&
  property &&
  typeof property === 'string' &&
  typeof onDefined === 'function'

const defineWindowProperty = (property, onDefined) => {
  const privateKey = `_stb_${property}`

  Object.defineProperty(window, property, {
    configurable: true,
    enumerable: true,
    get: function () {
      return window?.[privateKey]
    },
    set: function (val) {
      window[privateKey] = val
      onDefined()
    }
  })
}

export const onWindowPropertyDefined = (property, onDefined) => {
  if (!hasValidProps(property, onDefined)) return null

  if (window?.[property]) {
    onDefined()
  } else {
    defineWindowProperty(property, onDefined)
  }
}
