import root from 'window-or-global'

import { WEBVIEW_STRINGS, NAMED_WEBVIEWS } from './constants'

export const getUserAgent = () => root?.navigator?.userAgent || ''

export const isWebview = (userAgent) => {
  const webviewRegExp = new RegExp(`(${WEBVIEW_STRINGS.join('|')})`, 'ig')
  return !!userAgent.match(webviewRegExp)
}

export const getWebviewName = (userAgent) => {
  for (const webview of NAMED_WEBVIEWS) {
    const webviewRegExp = new RegExp(`(${webview.pattern})`, 'ig')
    /* istanbul ignore if */
    if (userAgent.match(webviewRegExp)) return webview.name
  }
  return 'webview'
}
