import { loadableReady } from '@loadable/component'
import { StyleSheet } from 'aphrodite'
import createInitialBrowserRouter from 'found/createInitialBrowserRouter'
import hasDom from 'has-dom'
import ReactDOM from 'react-dom'
import root from 'window-or-global'

import * as Sentry from '@src/sentry/browser'

import ErrorBoundary from '@components/error_boundary'

import render from '../routing/render'
import routeConfig from '../routing/routeConfig'

export const clientRoutingRender = async () => {
  Sentry.init()
  const BrowserRouter = await createInitialBrowserRouter({
    routeConfig,
    render
  })

  clientRender(BrowserRouter)
}

export const clientRender = (Component) => {
  if (!hasDom()) return

  loadableReady(() => {
    StyleSheet.rehydrate(root.__RENDERED_CLASS_NAMES__ || [])
    ReactDOM.hydrate(
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>,
      document.getElementById('root')
    )
  })
}
