import { css } from 'aphrodite'
import React, { useContext } from 'react'
import { QueryRenderer } from 'react-relay'

import BaseStyles from '@thebeansgroup/ui_base_styles'

import ImplementationContext from '@src/contexts/implementation'

import { isFirstPartyImplementationType } from '@helpers/implementation'

import environment from '../../relay'

import styles from './styles'

const Page = ({ variables, query, children }) => {
  const implementation = useContext(ImplementationContext)

  const renderContent = () => {
    if (!query) return children

    return (
      <QueryRenderer
        query={query}
        variables={variables}
        environment={environment}
        render={children}
      />
    )
  }

  return (
    <div
      className={css(
        styles.page,
        !isFirstPartyImplementationType(implementation) && styles.embeddedPage
      )}
    >
      <BaseStyles environment={process.env.NODE_ENV} />
      {renderContent()}
    </div>
  )
}

export default Page
