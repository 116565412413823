import { StyleSheet } from 'aphrodite'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

import { EMBEDDED_PAGE_HEIGHT } from '@helpers/implementation/constants'

const styles = StyleSheet.create({
  wrapper: {
    minHeight: EMBEDDED_PAGE_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  text: {
    marginTop: '15px'
  },

  expressBackground: {
    backgroundColor: Colours('white')
  }
})

export default styles
