/**
 * @generated SignedSource<<5bd6c699146f7addbfa68fedf17c4b11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "errorPageQuery",
    "selections": [
      {
        "alias": "viewer",
        "args": null,
        "concreteType": "AccountsViewer",
        "kind": "LinkedField",
        "name": "accountsViewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "pageViewTracker_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "errorPageQuery",
    "selections": [
      {
        "alias": "viewer",
        "args": null,
        "concreteType": "AccountsViewer",
        "kind": "LinkedField",
        "name": "accountsViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "userProfile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "idCode",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf41c1308ebb0da230fe1c7af728b66c",
    "id": null,
    "metadata": {},
    "name": "errorPageQuery",
    "operationKind": "query",
    "text": "query errorPageQuery {\n  viewer: accountsViewer {\n    ...pageViewTracker_viewer\n    id\n  }\n}\n\nfragment pageViewTracker_viewer on AccountsViewer {\n  userProfile {\n    email\n    idCode\n    id\n  }\n}\n"
  }
};
})();

node.hash = "d14d8209156041d5c5d18b1744362991";

module.exports = node;
