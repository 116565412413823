import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'

import ErrorPageContent from '@components/error_page_content'
import Page from '@components/page'
import PageHead from '@components/page_head'
import PageViewTracker from '@components/page_view_tracker'
import { getViewer } from '@helpers/index'

import { getErrorDetails, sendWebviewErrorEvent } from './helpers'

const ErrorPage = ({ t, status, message }) => {
  const getQuery = () => {
    return graphql`
      query errorPageQuery {
        viewer: accountsViewer {
          ...pageViewTracker_viewer
        }
      }
    `
  }

  const errorDetails = getErrorDetails(status, message)
  sendWebviewErrorEvent(status, errorDetails?.message)

  return (
    <Page query={getQuery()}>
      {({ props: queryProps }) => {
        return (
          <React.Fragment>
            <PageViewTracker
              viewer={getViewer(queryProps)}
              error={errorDetails}
            />
            <PageHead
              title={t('d_oops')}
              connectOfferPage={null}
            />
            <ErrorPageContent
              title={t('d_oops')}
              message={t("m_we_can't_find")}
            />
          </React.Fragment>
        )
      }}
    </Page>
  )
}

ErrorPage.propTypes = {
  t: PropTypes.func,
  message: PropTypes.string,
  status: PropTypes.number
}

export default withI18n(ErrorPage)
