import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking
} from '@snowplow/browser-plugin-link-click-tracking'
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing'
import {
  newTracker,
  enableActivityTracking,
  setUserId,
  trackPageView,
  trackSelfDescribingEvent,
  trackStructEvent
} from '@snowplow/browser-tracker'
import { v4 as uuidv4 } from 'uuid'

import ThirdPartyTracking from '@thebeansgroup/third_party_tracking'

import { OFFER_PAGE_TYPE } from '@constants/offer_page'
import { getOneTrustCookieConsentContexts } from '@helpers/cookie_consents'
import { logExceptions } from '@helpers/errors'
import { lowerCaseString } from '@helpers/index'
import { getConversionApiSnowplowContexts } from '@helpers/third_party_tracking'
import {
  ISSUANCE_TRACKING_SCHEMA,
  PAGEVIEW_ERROR_SCHEMA,
  OFFER_SCHEMA,
  WEB_VIEW_SCHEMA,
  MULTI_CCG_SCHEMA
} from '@tracker/constants'
import {
  getErrorCode,
  getNonRequiredFieldValue,
  convertNonRequiredFieldValueToString,
  getValidStringOrNull,
  getThirdPartyTrackingConfig,
  appTypePlugin
} from '@tracker/helpers'

export const setupSnowplow = (userId, appType) => {
  newTracker('co', process.env.SNOWPLOW_COLLECTOR_ENDPOINT, {
    appId: process.env.SNOWPLOW_APP_ID,
    platform: 'web',
    post: true,
    cookieDomain: process.env.SNOWPLOW_COOKIE_DOMAIN,
    postPath: '/studentbeans/t',
    contexts: {
      webPage: true
    },
    plugins: [
      PerformanceTimingPlugin(),
      LinkClickTrackingPlugin(),
      appTypePlugin(appType)
    ]
  })

  enableLinkClickTracking()

  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10
  })

  if (userId) setUserId(userId)
}

export const track = (category, action, label, property, value) => {
  trackStructEvent({ category, action, label, property, value })
}

export const unstructured = (schema, data, context) => {
  trackSelfDescribingEvent({ event: { schema, data }, context })
}

export const pageView = (title = null, context = []) => {
  trackPageView({ title, context })
}

export const trackThirdPartyPageView = (
  pageType,
  brandName,
  categoryName,
  facebookEventUid,
  contentId
) => {
  if (pageType === OFFER_PAGE_TYPE) {
    trackOfferView(brandName, categoryName, facebookEventUid, contentId)
  } else {
    logExceptions(() => ThirdPartyTracking.pageView(facebookEventUid))
  }
}

export const trackOfferView = (
  brandName,
  categoryName,
  facebookEventUid,
  contentId
) => {
  logExceptions(() =>
    ThirdPartyTracking.offerPageView(
      brandName,
      categoryName,
      facebookEventUid,
      contentId
    )
  )
}

export const trackIssuance = ({
  offer,
  brand,
  country,
  isLoggedIn,
  issuance,
  affiliateLink,
  email
}) => {
  const category = offer.primaryCategory || {}

  const data = {
    app_id: process.env.SNOWPLOW_APP_ID,
    platform: 'web',
    publisher: 'Connect',
    offer_title: offer.title,
    offer_slug: offer.slug,
    offer_uid: offer.offerId,
    redemption_type: offer.redemptionType,
    redemption_class: offer.redemptionClass,
    redemption_method: offer.redemptionMethod,
    start_date: offer.startDate,
    end_date: offer.endDate,
    boosted: offer.boosted,
    logged_in: isLoggedIn,
    student_verified: offer.verified,
    brand_title: brand.name,
    brand_uid: brand.brandId,
    brand_slug: brand.slug,
    country,
    category_uid: category.categoryId,
    category_name: category.name,
    issuance_uid: issuance.uid,
    syndication_network: null,
    user_id: issuance.sbidNumber,
    affiliate_network: issuance.affiliateNetwork,
    code: issuance.code.code,
    redirect_url: getValidStringOrNull(affiliateLink),
    consumer_group: offer.consumerGroup
  }

  const pixelDedupeUid = uuidv4()
  const pathName = window.location.pathname
  const contexts = [
    ...getConversionApiSnowplowContexts(pixelDedupeUid, email, pathName),
    ...getOneTrustCookieConsentContexts()
  ]

  unstructured(ISSUANCE_TRACKING_SCHEMA, data, contexts)
  logExceptions(() =>
    ThirdPartyTracking.purchase(
      category?.name,
      brand?.name,
      issuance?.uid,
      pixelDedupeUid
    )
  )
}

export const initThirdPartyTracking = (email, domains) => {
  logExceptions(() =>
    ThirdPartyTracking.init(email, getThirdPartyTrackingConfig(domains))
  )
}

export const getErrorCustomContext = (data) => {
  return {
    schema: PAGEVIEW_ERROR_SCHEMA,
    data: {
      error_code: getErrorCode(data.code),
      error_message: data.message || ''
    }
  }
}

export const getWebviewCustomContext = (name) => {
  return {
    schema: WEB_VIEW_SCHEMA,
    data: { name }
  }
}

export const getOfferCustomContext = (data) => {
  return {
    schema: OFFER_SCHEMA,
    data: {
      active_redemption_type_id: convertNonRequiredFieldValueToString(
        data.activeRedemptionTypeId
      ),
      boosted: data.boosted,
      boost_end_date: data.boostEndDate,
      boost_start_date: data.boostStartDate,
      brand_name: data.brandName,
      brand_slug: data.brandSlug,
      brand_uid: data.brandUid,
      consumer_group: data.consumerGroup,
      country: data.countryCode,
      offer_end_date: getNonRequiredFieldValue(data.offerEndDate),
      offer_start_date: data.offerStartDate,
      offer_slug: data.offerSlug,
      offer_title: data.offerTitle,
      offer_uid: data.offerUid,
      primary_category_name: getNonRequiredFieldValue(data.primaryCategoryName),
      primary_category_uid: getNonRequiredFieldValue(data.primaryCategoryUid),
      redemption_class: lowerCaseString(data.redemptionClass),
      redemption_method: getNonRequiredFieldValue(data.redemptionMethod),
      redemption_type: lowerCaseString(data.redemptionType)
    }
  }
}

export const getMultiCCGConnectCustomContext = (multiCCGContext) => {
  return {
    schema: MULTI_CCG_SCHEMA,
    data: {
      brand_name: multiCCGContext.brandName,
      brand_slug: multiCCGContext.brandSlug,
      brand_uid: multiCCGContext.brandUid,
      consumer_groups: multiCCGContext.consumerGroups,
      country: multiCCGContext.countryCode,
      primary_category_name: getNonRequiredFieldValue(
        multiCCGContext.primaryCategoryName
      ),
      primary_category_uid: getNonRequiredFieldValue(
        multiCCGContext.primaryCategoryUid
      )
    }
  }
}
