import * as Sentry from '@sentry/browser'

import { getBrowserUrl, isDevelopmentEnv } from './helpers'

export const init = () => {
  if (isDevelopmentEnv()) return null

  return Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: getBrowserUrl(),
    release: process.env.APP_VERSION,
    allowUrls: [/https?:\/\/(.*\.)?((cdn|www|connect)\.)?studentbeans\.com/]
  })
}

export default init
