import {
  BEANS_ID_BRAND,
  CONNECT_NAME,
  GRAD_BEANS_BRAND,
  STUDENT_BEANS_BRAND
} from './constants'

export const getPageTitle = (title, connectOfferPage) => {
  const beansBrand =
    getBeansBrand(connectOfferPage?.offers) ?? STUDENT_BEANS_BRAND
  const brandName = connectOfferPage?.brand?.name ?? CONNECT_NAME

  if (!title) return `${brandName} | ${beansBrand}`

  return `${title} | ${brandName} | ${beansBrand}`
}

const getBeansBrand = (offers) => {
  if (!offers) return null

  const consumerGroupsFromOffers = getListOfConsumerGroupsFromOffers(offers)

  if (shouldDisplayBeansIdBrand(consumerGroupsFromOffers)) return BEANS_ID_BRAND

  if (shouldDisplayGradBeansBrand(consumerGroupsFromOffers))
    return GRAD_BEANS_BRAND

  return STUDENT_BEANS_BRAND
}

const getListOfConsumerGroupsFromOffers = (offers) => {
  const consumerGroupsFromOffers = []

  offers.forEach((offer) => {
    if (offer?.enabled) {
      consumerGroupsFromOffers.push(offer.consumerGroup?.toLowerCase())
    }
  })

  return consumerGroupsFromOffers
}

const shouldDisplayBeansIdBrand = (consumerGroupsFromOffers) => {
  const displayBeansIdBrand = consumerGroupsFromOffers.some(
    (offer) => offer !== 'student' && offer !== 'graduate'
  )
  return displayBeansIdBrand
}

const shouldDisplayGradBeansBrand = (consumerGroupsFromOffers) => {
  const displayGradBeansBrand = consumerGroupsFromOffers.every(
    (offer) => offer !== 'student' && offer === 'graduate'
  )
  return displayGradBeansBrand
}
