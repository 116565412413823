export const IMPRESSION_CLICK_SCHEMA_URI =
  'iglu:com.studentbeans/impression_click/jsonschema/1-0-0'

export const IMPRESSION_EVENT_NAME = 'stb_impression_event'

export const MULTI_CCG_PAGE = 'connect_multi_ccg_page'
export const MULTI_OFFER_PAGE = 'connect_multi_offer_page'
export const OFFER_PAGE = 'connect_offer_page'
export const CODE_REVEAL_PAGE = 'connect_code_reveal_page'
export const CODE_PAGE = 'connect_code_page'
