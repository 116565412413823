import root from 'window-or-global'

import { isString } from '@src/helpers/index'

import { DEFAULT_COUNTRY_CODE } from './constants'

export const getServerErrors = () => {
  const error = root && root.__SERVER_ERROR__
  delete root.__SERVER_ERROR__
  return error
}

export const getDefaultRoute = (params) => {
  const implementation = getImplementationUrlPart(params?.implementation)
  const country = getDefaultCountry(params?.country)
  return `/${process.env.CONNECT_VERSION}/${implementation}${params.slug}/${country}`
}

export const getDefaultOfferRoute = (params, queryString) => {
  return `${getDefaultRoute(params)}/${getQueryString(queryString)}`
}

export const getDefaultOfferTermsRoute = (params, queryString) => {
  return `${getDefaultRoute(params)}/terms/${getQueryString(queryString)}`
}

export const getDefaultCountry = (country) => country || DEFAULT_COUNTRY_CODE

export const getImplementationUrlPart = (implementation) =>
  implementation && implementation !== 'express' ? `${implementation}/` : ''

export const getQueryString = (queryString) => {
  if (!queryString || !isString(queryString)) return ''

  return getValidQueryString(queryString)
}

export const getValidQueryString = (queryString) => {
  if (!queryString.startsWith('?')) return `?${queryString}`

  return queryString
}

export const addQueryStringValue = (router, match, newQueryStringObj) => {
  const params = new URLSearchParams(match.location.search)

  if (match.location.query[newQueryStringObj.name]) {
    params.delete(newQueryStringObj.name)
  }

  params.append(newQueryStringObj.name, newQueryStringObj.value)

  router.push({
    pathname: match.location.pathname,
    search: `?${params.toString()}`
  })
}

export const addMultipleQueryStringValues = (
  router,
  match,
  newQueryStringArr
) => {
  const params = new URLSearchParams(match.location.search)

  newQueryStringArr.forEach((newQueryStringObj) => {
    if (match.location.query[newQueryStringObj.name]) {
      params.delete(newQueryStringObj.name)
    }

    params.append(newQueryStringObj.name, newQueryStringObj.value)
  })

  router.push({
    pathname: match.location.pathname,
    search: `?${params.toString()}`
  })
}
