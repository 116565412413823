export const ISSUANCE_TRACKING_SCHEMA =
  'iglu:com.studentbeans/issuance/jsonschema/1-7-0'
export const PAGEVIEW_ERROR_SCHEMA =
  'iglu:com.studentbeans/page_view_error/jsonschema/1-0-0'
export const OFFER_SCHEMA = 'iglu:com.studentbeans/offer/jsonschema/4-3-0'
export const WEB_VIEW_SCHEMA = 'iglu:com.studentbeans/web_view/jsonschema/1-0-0'
export const MULTI_CCG_SCHEMA =
  'iglu:com.studentbeans/multi_consumer_group_connect/jsonschema/1-0-0'
export const IMPRESSION_VIEW_SCHEMA =
  'iglu:com.studentbeans/impression_view/jsonschema/1-0-0'
export const IMPRESSION_LOAD_SCHEMA =
  'iglu:com.studentbeans/impression_load/jsonschema/1-0-0'
