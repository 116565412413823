import hasDom from 'has-dom'
import cookies from 'js-cookie'
import root from 'window-or-global'

import { AUTH_TOKEN_NAME } from '@src/client/constants'

import { isBrowserAndroidChrome } from '@helpers/browser'
import { isInIframe } from '@helpers/iframe'

export function hasClientSideRelayError(error) {
  return error && hasDom()
}

export const getToken = () => {
  // https://wearepion.atlassian.net/browse/MER-3686
  if (isInIframe(root) && isBrowserAndroidChrome()) {
    return ''
  }

  return cookies.get(AUTH_TOKEN_NAME)
}
