export const FACEBOOK_CONVERSION_API_SCHEMA =
  'iglu:com.studentbeans/facebook_conversion_api/jsonschema/1-0-0'
export const SNAPCHAT_CONVERSION_API_SCHEMA =
  'iglu:com.studentbeans/snapchat_conversion_api/jsonschema/1-1-0'
export const TIKTOK_CONVERSION_API_SCHEMA =
  'iglu:com.studentbeans/tiktok_conversion_api/jsonschema/1-0-0'

export const FACEBOOK_ID = '_fbp'
export const FACEBOOK_CLICK_ID = '_fbc'

export const SNAPCHAT_USER_ID_COOKIE_NAME = '_scid'

export const TIKTOK_ID = '_ttp'
export const TIKTOK_CLICK_ID = 'ttclid'
