import { StyleSheet } from 'aphrodite'

import { baby } from '@thebeansgroup/sb-ui/style_helpers/queries'

import { EMBEDDED_PAGE_HEIGHT } from '@helpers/implementation/constants'

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: EMBEDDED_PAGE_HEIGHT
  },

  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    margin: '28px 44px',
    textAlign: 'center',

    ...baby({
      margin: '28px 20px'
    })
  },

  title: {
    marginBottom: '8px'
  },

  logo: {
    marginBottom: '48px',

    ...baby({
      marginBottom: '24px'
    })
  }
})

export default styles
