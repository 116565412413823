import {
  COOKIE_CODE_KEY_MAP,
  ONE_TRUST_ACTIVE_GROUPS_KEY,
  ONE_TRUST_KEY,
  COOKIE_CONSENT_SCHEMA
} from '@constants/cookie_consents'
import { onWindowPropertyDefined } from '@helpers/window'
import { unstructured } from '@tracker/index'

export const getConsentsFromOneTrust = () => {
  return window?.[ONE_TRUST_ACTIVE_GROUPS_KEY]
}

export const oneTrustOnConsentChanged = (callback) => {
  onWindowPropertyDefined(ONE_TRUST_KEY, () => {
    window?.[ONE_TRUST_KEY]?.OnConsentChanged(callback)
  })
}

export const getUserConsentsByCode = (consents) => {
  if (!hasValidConsents(consents)) return null

  const userConsents = {}
  for (const key in COOKIE_CODE_KEY_MAP) {
    userConsents[COOKIE_CODE_KEY_MAP[key]] = !!consents.includes(key)
  }
  return userConsents
}

export const hasValidConsents = (consents) => {
  if (!consents || typeof consents !== 'string') return false

  return consents.split(',').some((value) => !!value)
}

export const getOneTrustCookieConsentContexts = () => {
  const oneTrustConsents = getConsentsFromOneTrust()
  const userConsents = getUserConsentsByCode(oneTrustConsents)
  return getCookieConsentContext(userConsents)
}

export const getCookieConsentContext = (userConsents) => {
  if (!userConsents) return []

  return [
    {
      schema: COOKIE_CONSENT_SCHEMA,
      data: userConsents
    }
  ]
}

export const handleConsentChanged = (contexts) => {
  const oneTrustConsents = getConsentsFromOneTrust()
  const userConsents = getUserConsentsByCode(oneTrustConsents)
  if (userConsents?.targeting !== true) return

  const contextWithoutCookieConsents =
    removeCookieConsentContextSchema(contexts)
  sendPageViewProxy(userConsents, [
    ...contextWithoutCookieConsents,
    ...getCookieConsentContext(userConsents)
  ])
}

export const removeCookieConsentContextSchema = (contexts) => {
  return contexts.filter((context) => context.schema !== COOKIE_CONSENT_SCHEMA)
}

export const sendPageViewProxy = (userConsents, contexts) => {
  unstructured(COOKIE_CONSENT_SCHEMA, userConsents, contexts)
}
