import {
  getErrorCustomContext,
  getWebviewCustomContext,
  getOfferCustomContext,
  getMultiCCGConnectCustomContext
} from '@src/tracker'

import { getOneTrustCookieConsentContexts } from '@helpers/cookie_consents'
import { getConversionApiSnowplowContexts } from '@helpers/third_party_tracking'
import { getUserAgent, isWebview, getWebviewName } from '@helpers/webview'

import {
  EMBEDDED_APP_TYPE,
  EXPRESS_APP_TYPE,
  HOSTED_APP_TYPE
} from '../constants'

export const getUserEmail = (email) => email ?? ''

export const getCustomContexts = (
  error,
  offerContext,
  facebookEventUid,
  email,
  contentId,
  multiCCGContext
) => {
  const customContexts = [
    getErrorContext(error),
    getWebviewContext(),
    getOfferContext(offerContext),
    getMultiCCGContext(multiCCGContext),
    ...getConversionApiSnowplowContexts(facebookEventUid, email, contentId),
    ...getOneTrustCookieConsentContexts()
  ]
  return customContexts.filter(Boolean)
}

export const getErrorContext = (error) => {
  if (!error) return null

  return getErrorCustomContext({
    code: error.status,
    message: error.message
  })
}

export const getOfferContext = (offerContext) => {
  if (!offerContext) return null

  return getOfferCustomContext(offerContext)
}

export const getMultiCCGContext = (multiCCGContext) => {
  if (!multiCCGContext) return null

  return getMultiCCGConnectCustomContext(multiCCGContext)
}

export const getWebviewContext = () => {
  const userAgent = getUserAgent()
  if (!isWebview(userAgent)) return null

  const name = getWebviewName(userAgent)
  return getWebviewCustomContext(name)
}

export const getUserId = (idCode) => idCode || null

export const getAppType = (isExpress) => {
  if (isExpress) return EXPRESS_APP_TYPE

  if (window.location.pathname.includes(HOSTED_APP_TYPE)) return HOSTED_APP_TYPE

  return EMBEDDED_APP_TYPE
}
