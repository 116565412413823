import ErrorPage from '@pages/error_page'
import * as Sentry from '@sentry/browser'
import React from 'react'

import { getStatusFromError } from './helpers'

class ErrorBoundary extends React.Component {
  constructor() {
    super()
    this.state = {
      hasError: false,
      status: null
    }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      status: getStatusFromError(error)
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage status={this.state.status} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
