/**
 * @generated SignedSource<<0e08ec13f06653e401b8d0e6a29c60d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pageViewTracker_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "idCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "411f150bd3bf09841022666764f21ea8";

module.exports = node;
