import { withRouter } from 'found'
import PropTypes from 'prop-types'
import { useEffect, useCallback } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { v4 as uuidv4 } from 'uuid'

import { useIsExpressConnect } from '@src/hooks/use_is_express_connect'
import {
  setupSnowplow,
  initThirdPartyTracking,
  pageView,
  trackThirdPartyPageView
} from '@src/tracker'

import {
  handleConsentChanged,
  oneTrustOnConsentChanged
} from '@helpers/cookie_consents'
import { getPageTitle } from '@helpers/metadata'
import useImpressionListener from '@hooks/use_impression_listener'

import {
  getUserEmail,
  getCustomContexts,
  getUserId,
  getAppType
} from './helpers'

const PageViewTracker = ({
  pageType,
  brandName,
  categoryName,
  viewer,
  connectOfferPage,
  title,
  error,
  offerContext,
  onLoad,
  multiCCGContext,
  match
}) => {
  useImpressionListener()
  const isExpress = useIsExpressConnect(match)

  const handlePageLoad = useCallback(() => {
    if (onLoad) onLoad()
  }, [onLoad])

  useEffect(() => {
    setupSnowplow(getUserId(viewer?.userProfile?.idCode), getAppType(isExpress))
    initThirdPartyTracking(
      getUserEmail(viewer?.userProfile?.email),
      connectOfferPage?.domains
    )
  }, [
    viewer?.userProfile?.email,
    viewer?.userProfile?.idCode,
    connectOfferPage?.domains,
    isExpress
  ])

  useEffect(() => {
    const facebookEventUid = uuidv4()
    const pathName = window.location.pathname
    const contexts = getCustomContexts(
      error,
      offerContext,
      facebookEventUid,
      getUserEmail(viewer?.userProfile?.email),
      pathName,
      multiCCGContext
    )
    pageView(getPageTitle(title, connectOfferPage), contexts)
    trackThirdPartyPageView(
      pageType,
      brandName,
      categoryName,
      facebookEventUid,
      pathName
    )
    oneTrustOnConsentChanged(() => handleConsentChanged(contexts))
    handlePageLoad()
  }, [
    pageType,
    brandName,
    categoryName,
    title,
    error,
    offerContext,
    handlePageLoad,
    onLoad,
    viewer?.userProfile?.email,
    connectOfferPage,
    multiCCGContext
  ])

  return null
}

PageViewTracker.propTypes = {
  pageType: PropTypes.string,
  title: PropTypes.string,
  viewer: PropTypes.shape({
    userProfile: PropTypes.shape({
      email: PropTypes.string,
      idCode: PropTypes.string
    })
  }),
  connectOfferPage: PropTypes.shape({
    domains: PropTypes.array
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number
  }),
  onLoad: PropTypes.func,
  offerContext: PropTypes.object,
  brandName: PropTypes.string,
  categoryName: PropTypes.string
}

export default createFragmentContainer(withRouter(PageViewTracker), {
  viewer: graphql`
    fragment pageViewTracker_viewer on AccountsViewer {
      userProfile {
        email
        idCode
      }
    }
  `,
  connectOfferPage: graphql`
    fragment pageViewTracker_connectOfferPage on ConnectOfferPage {
      domains
      offers {
        consumerGroup
        enabled
      }
      brand {
        name
      }
    }
  `
})
