import {
  IMPLEMENTATION_TYPES,
  FIRST_PARTY_IMPLEMENTATION_TYPES,
  INAPP_TYPE
} from './constants'

export function isValidImplementationType(name) {
  return IMPLEMENTATION_TYPES.includes(name)
}

export function getValidImplementationType(name) {
  if (!isValidImplementationType(name)) return ''

  return name
}

export function isFirstPartyImplementationType(name) {
  return FIRST_PARTY_IMPLEMENTATION_TYPES.includes(name)
}

export function isThirdPartyImplementationType(name) {
  return !isFirstPartyImplementationType(name)
}

export function isInappImplementationType(name) {
  return name === INAPP_TYPE
}
