import * as Sentry from '@sentry/browser'
import root from 'window-or-global'

import { IMPRESSION_EVENT_NAME } from '@constants/impression_tracking'
import { isInIframe } from '@helpers/iframe'
import { isInteger } from '@helpers/index'
import { getParentUrl, isDomainWhitelisted } from '@helpers/url'

import { IMPRESSION_LOAD_SCHEMA, IMPRESSION_VIEW_SCHEMA } from '../constants'

export const getErrorCode = (code) => {
  if (!isValidErrorCode(code)) return null

  return code
}

export const isValidErrorCode = (code) => !!(code && isInteger(code))

export const getNonRequiredFieldValue = (value) => value || null

export const convertNonRequiredFieldValueToString = (value) => {
  const valueOrNull = getNonRequiredFieldValue(value)
  if (!valueOrNull) return valueOrNull

  return String(valueOrNull)
}

export const getValidStringOrNull = (str) => {
  if (!!str && typeof str === 'string') return str

  return null
}

export const getThirdPartyTrackingConfig = (domains) => {
  const disabled = shouldDisableTracking(domains)
  return {
    snapchatTracker: {
      disabled: !!isInIframe(root) || disabled
    },
    facebookTracker: {
      disabled
    },
    googleTracker: {
      disabled
    },
    twitterTracker: {
      disabled
    },
    contentsquareTracker: {
      disabled
    },
    tiktokTracker: {
      disabled
    },
    taboolaTracker: {
      disabled
    }
  }
}

export const shouldDisableTracking = (domains) => {
  if (!isInIframe(root)) return false

  return !isDomainWhitelisted(getParentUrl(), domains)
}

export const storeImpressionView = (impressionId) => {
  storeEvent('impression_view', { impression_id: impressionId })
}

export const storeImpressionLoad = (data, impressionId) => {
  storeEvent('impression_load', {
    ...data,
    impression_id: impressionId
  })
}

export const reportErrorHandler = (error, handler) => {
  Sentry.withScope(function (scope) {
    scope.setTag('sb_handler', handler)
    Sentry.captureException(error)
  })
}

export const storeEvent = async (storeName, value) => {
  try {
    sendEvent({ [storeName]: value })
  } catch (error) {
    reportErrorHandler(error, 'useImpressionTracking')
  }
}

export const sendEvent = (data) => {
  document.dispatchEvent(
    new window.CustomEvent(IMPRESSION_EVENT_NAME, { detail: data })
  )
}

export const constructSnowplowEventData = (data) => {
  const viewData = getEventData(data?.impressionView, IMPRESSION_VIEW_SCHEMA)

  const loadData = getEventData(data?.impressionLoad, IMPRESSION_LOAD_SCHEMA)

  const events = [...viewData, ...loadData]
  const batchedEvents = batchArray(events, 20)
  return batchedEvents.map((batch) => {
    return {
      event: batch?.[0],
      contexts: batch.shift()
    }
  })
}

export const getEventData = (data, schema) => {
  if (!hasData(data)) return []

  return data.map((item) => {
    return {
      schema,
      data: item
    }
  })
}

export const hasData = (data) => Array.isArray(data) && data.length > 0

export const batchArray = (array, size) => {
  if (array.length <= size) {
    return [array]
  }

  const itemsBeforeLimit = array.slice(0, size)
  const itemsAfterLimit = batchArray(array.slice(size), size)

  return [itemsBeforeLimit, ...itemsAfterLimit]
}

export const appTypePlugin = (appType) => ({
  contexts: () => [
    {
      schema: 'iglu:com.studentbeans/sb_standard_context/jsonschema/1-1-0',
      data: {
        app_id_type: appType
      }
    }
  ]
})
