export const INAPP_TYPE = 'inapp'
export const HOSTED_TYPE = 'hosted'
export const EMBEDDED_TYPE = 'embedded'
export const DEFAULT_TYPE = 'default'

export const IMPLEMENTATION_TYPES = [
  INAPP_TYPE,
  HOSTED_TYPE,
  EMBEDDED_TYPE,
  DEFAULT_TYPE
]

export const FIRST_PARTY_IMPLEMENTATION_TYPES = [
  INAPP_TYPE,
  HOSTED_TYPE,
  DEFAULT_TYPE
]

export const EMBEDDED_PAGE_HEIGHT = '720px'
