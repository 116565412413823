// Taken from https://github.com/atomantic/is-ua-webview/blob/master/data/rules.js
const GENERAL_WEBVIEW_STRING = 'WebView'
const IOS_WEBVIEW = '(iPhone|iPod|iPad)(?!.*Safari)'
const ANDROID_WEBVIEW =
  'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})'
const OLD_CHROME_ANDROID_WEBVIEW = 'Linux; U; Android'

// Taken from https://developers.whatismybrowser.com/useragents/explore/software_type_specific/in-app-browser/1
const SNAPCHAT_WEBVIEW = 'Snapchat'
const FACEBOOK_WEBVIEW = '(FBAN/FBIOS|FBAV)'
const INSTAGRAM_WEBVIEW = 'Instagram'
const TWITTER_WEBVIEW = 'Twitter'

export const NAMED_WEBVIEWS = [
  {
    name: 'studentbeans',
    pattern: 'StudentBeans'
  },
  {
    name: 'snapchat',
    pattern: SNAPCHAT_WEBVIEW
  },
  {
    name: 'facebook',
    pattern: FACEBOOK_WEBVIEW
  },
  {
    name: 'instagram',
    pattern: INSTAGRAM_WEBVIEW
  },
  {
    name: 'twitter',
    pattern: TWITTER_WEBVIEW
  }
]

export const WEBVIEW_STRINGS = [
  GENERAL_WEBVIEW_STRING,
  IOS_WEBVIEW,
  ANDROID_WEBVIEW,
  OLD_CHROME_ANDROID_WEBVIEW,
  SNAPCHAT_WEBVIEW,
  FACEBOOK_WEBVIEW,
  INSTAGRAM_WEBVIEW,
  TWITTER_WEBVIEW
]
