import {
  sendGeneralErrorEvent,
  sendPageNotFoundErrorEvent
} from '@helpers/inapp'

export const getErrorDetails = (status, message) => {
  return {
    status: status || 500,
    message: message || 'Error'
  }
}

export const sendWebviewErrorEvent = (status, message) => {
  if (status === 404) sendPageNotFoundErrorEvent(message)
  else sendGeneralErrorEvent(message)
}
