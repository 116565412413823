import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import root from 'window-or-global'

import { getToken } from './helpers'

export function fetchQuery(operation, variables) {
  return root
    .fetch(`${process.env.GRAPHQL_URL}/graphql/v1/query`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: operation.text, variables })
    })
    .then((response) => {
      return response.json()
    })
}

const recordSource = new RecordSource()
const store = new Store(recordSource)
const network = Network.create(fetchQuery)
const environment = new Environment({ network, store })

export default environment
