export const COOKIE_CODE_KEY_MAP = {
  C0001: 'strictly_necessary',
  C0002: 'performance',
  C0003: 'functional',
  C0004: 'targeting',
  C0005: 'social_media'
}

export const ONE_TRUST_KEY = 'OneTrust'

export const ONE_TRUST_ACTIVE_GROUPS_KEY = 'OnetrustActiveGroups'

export const COOKIE_CONSENT_SCHEMA =
  'iglu:com.studentbeans/cookie_consents/jsonschema/1-0-0'
