export const COUNTRY_PART = ':country'

export const IMPLEMENTATION_PART = ':implementation(inapp|hosted)'

export const SLUG_PART = ':slug'

export const OFFER_ROUTE_WITH_NO_COUNTY = `${process.env.CONNECT_VERSION}/${IMPLEMENTATION_PART}?/${SLUG_PART}`

export const IMPLEMENTATION_OFFER_ROUTE = `${IMPLEMENTATION_PART}/${SLUG_PART}/${COUNTRY_PART}`

export const OFFER_ROUTE = `${process.env.CONNECT_VERSION}/${SLUG_PART}/${COUNTRY_PART}`

export const INAPP_OFFER_ROUTE = `${process.env.CONNECT_VERSION}/:implementation(inapp)/${SLUG_PART}/${COUNTRY_PART}`

export const HOSTED_OFFER_ROUTE = `${process.env.CONNECT_VERSION}/:implementation(hosted)/${SLUG_PART}/${COUNTRY_PART}`

export const VERIFICATION_SUCCESSFUL_ROUTE = `${process.env.CONNECT_VERSION}/${IMPLEMENTATION_PART}/verification-successful/${COUNTRY_PART}`

export const DEFAULT_COUNTRY_CODE = 'uk'
