import root from 'window-or-global'

import { isInIframe } from '@helpers/iframe'
import { isString } from '@helpers/index'

import {
  OFFER_PATH_QUERY_STRING_KEY,
  REGEXP_MATCH_SUBDOMAINS,
  IS_REGEXP_MATCHER
} from './constants'

export const getUrlParamByName = (name) => {
  const params = getUrlParams()
  for (const param of params) {
    const [key, value] = param.split('=')
    if (decodeURIComponent(key) === name) {
      return decodeURIComponent(value)
    }
  }
  return ''
}

export const getUrlParamKeyValues = (params) => {
  const urlParamKeyValues = {}
  for (const param of params) {
    const [key, value] = param.split('=')
    if (key) urlParamKeyValues[key] = decodeURIComponent(value)
  }
  return urlParamKeyValues
}

export const getUrlParamStringFromObject = (params) => {
  return Object.entries(params)
    .map(([key, value]) => value && `${key}=${value}`)
    .filter(Boolean)
    .join('&')
}

export const getUrlParams = () => {
  const query = root.location.search.substring(1)
  return query.split('&')
}

export const getPropFromUrl = (url, prop) => {
  try {
    const urlObj = new URL(url)
    return urlObj?.[prop]
  } catch (error) {
    return ''
  }
}

export const stripTrailingSlashFromString = (str) => {
  if (!isString(str)) return ''

  return str.replace(/\/$/, '')
}

export const sanitiseDomainsWhitelist = (domainsWhitelist) => {
  return domainsWhitelist.map((domain) => stripTrailingSlashFromString(domain))
}

export const isDomainWhitelisted = (url, domainsWhitelist) => {
  if (!url || !Array.isArray(domainsWhitelist)) return false

  const origin = getPropFromUrl(url, 'origin')
  const hostname = getPropFromUrl(url, 'host')
  const whitelist = sanitiseDomainsWhitelist(domainsWhitelist)
  return whitelist.some(hasWhitelistMatch.bind(null, origin, hostname))
}

export function hasWhitelistMatch(origin, hostname, whitelistedDomain) {
  const domainRegexp = getDomainRegexp(whitelistedDomain)
  if (domainRegexp) return !!hostname.match(domainRegexp)

  return isDomainExactMatch(whitelistedDomain, origin, hostname)
}

export const isDomainExactMatch = (whitelistedDomain, origin, hostname) => {
  return whitelistedDomain === origin || whitelistedDomain === hostname
}

export const isDomainRegexp = (domain) => domain.includes(IS_REGEXP_MATCHER)

export const getDomainRegexp = (domain) => {
  if (!isDomainRegexp(domain)) return ''

  const replace = domain.replace(IS_REGEXP_MATCHER, REGEXP_MATCH_SUBDOMAINS)
  return `^${replace}$`
}

export const prependHttp = (url) => {
  if (typeof url !== 'string') return ''

  if (!/^https?:\/\//i.test(url)) {
    return 'http://' + url
  }
  return url
}

export const getStbOfferPath = () =>
  getUrlParamByName(OFFER_PATH_QUERY_STRING_KEY)

export const getUrlQueryString = () => {
  if (!root.location) return ''

  return root.location.search
}

export const getReferrer = (root) => {
  try {
    return root.document.referrer
  } catch {
    return ''
  }
}

export const getParentUrl = () => {
  const stbOfferPath = getStbOfferPath()
  if (stbOfferPath) return stbOfferPath

  if (isInIframe(root)) return getReferrer(root)

  return ''
}
