import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { createFragmentContainer, graphql } from 'react-relay'

import { getPageTitle } from '@helpers/metadata'

const PageHead = ({ title, connectOfferPage }) => {
  const pageTitle = getPageTitle(title, connectOfferPage)

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  )
}

PageHead.propTypes = {
  title: PropTypes.string
}

export default createFragmentContainer(PageHead, {
  connectOfferPage: graphql`
    fragment pageHead_connectOfferPage on ConnectOfferPage {
      offers {
        consumerGroup
        enabled
      }
      brand {
        name
      }
    }
  `
})
