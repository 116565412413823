import { getStbOfferPath } from '@helpers/url'

export const isInIframe = (root) => {
  if (getStbOfferPath()) return true

  try {
    return root.self !== root.top
  } catch (e) {
    return true
  }
}
