export const getNodeUrl = () => {
  const apiKey = process.env.SENTRY_NODE_API_KEY
  const organisation = process.env.SENTRY_NODE_ORGANISATION_ID
  const project = process.env.SENTRY_NODE_PROJECT_ID
  return `https://${apiKey}@${organisation}.ingest.sentry.io/${project}`
}

export const getBrowserUrl = () => {
  const apiKey = process.env.SENTRY_BROWSER_API_KEY
  const organisation = process.env.SENTRY_BROWSER_ORGANISATION_ID
  const project = process.env.SENTRY_BROWSER_PROJECT_ID
  return `https://${apiKey}@${organisation}.ingest.sentry.io/${project}`
}

export const handleEnvironment = (event, hint) => {
  if (isDevelopmentEnv()) {
    logError(hint)
    return null
  }
  return event
}

export const isDevelopmentEnv = () => process.env.NODE_ENV === 'development'

export const logError = (hint) => console.error(getSentryHint(hint))

export const getSentryHint = (hint) =>
  hint.originalException || hint.syntheticException
