import logo from '@images/student-beans-logo.svg'
import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Body, LargeTitle } from '@thebeansgroup/sb-ui/typography'

import styles from './styles'

const ErrorPageContent = (props) => {
  const renderTitle = () => {
    return (
      <div className={css(styles.title)}>
        <LargeTitle
          component='h1'
          colour={Colours('grey', 500)}
        >
          {props.title}
        </LargeTitle>
      </div>
    )
  }

  const renderMessage = () => {
    return (
      <Body
        component='h2'
        colour={Colours('grey', 500)}
      >
        {props.message}
      </Body>
    )
  }

  return (
    <div className={css(styles.wrapper)}>
      <div className={css(styles.main)}>
        {renderTitle()}
        {renderMessage()}
      </div>

      <div className={css(styles.logo)}>
        <img
          src={logo}
          alt='StudentBeans'
        />
      </div>
    </div>
  )
}

ErrorPageContent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
}

ErrorPageContent.defaultProps = {
  title: 'Oops, Something went wrong.',
  message:
    "We can't seem to find what you're looking for right now, try again soon."
}

export default ErrorPageContent
