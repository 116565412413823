import * as Sentry from '@sentry/browser'

export const logExceptions = (func) => {
  try {
    func()
  } catch (e) {
    Sentry.captureException(e)
    console.error(e)
  }
}
